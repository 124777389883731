// Module for initializing Swiper with backdrop functionality

// Function to initialize backdrop behavior
function initBackdrop(swiper) {
    const clickableDivs = document.querySelectorAll('.gid-item');
    const closeBut = document.querySelector('a.close');
    const swiperGallery = document.querySelector('.custom-gallery-wrapper');

    // Function to close the swiper gallery
    function closeSwiperGallery() {
        swiperGallery.classList.toggle('d-none');
    }

    // Register close button to close overlay/backdrop with gallery
    if (closeBut) {
        closeBut.addEventListener('click', closeSwiperGallery);
    }

    // Add click events to each clickable div
    clickableDivs.forEach(function (div) {
        div.addEventListener('click', function () {
            swiperGallery.classList.toggle('d-none');
            const index = this.dataset.index;
            swiper.slideTo(index);
        });
    });

    // Add keydown event to close gallery on "Escape" key press
    document.addEventListener('keydown', function (event) {
        if (event.key === "Escape" || event.keyCode === 27) {
            if (!swiperGallery.classList.contains('d-none')) {
                closeSwiperGallery();
            }
        }
    });
}

// Function to initialize the Swiper instance
function initWpSwiper(Swiper) {
    // alert('swprt!');
    const swiper = new Swiper('.swiper-wp', {
        loop: false,
        autoHeight: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            dynamicBullets: true,
            dynamicMainBullets: 3,
        },
        keyboard: {
            enabled: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        on: {
            init: function () {
                initBackdrop(this); // Pass the current swiper instance to initBackdrop
            },
        },
    });
}

// Export the Swiper initialization function
export { initWpSwiper };
